<template>
  <section id="rekapituliasi-persetujuan-direktur">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            class="mt-2"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>
        <b-card>
          <!-- search input -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_data_instansi"
                  v-model="master_data_instansi"
                  required
                  name="master_data_instansi"
                  :options="referenceData.ref_instansi"
                  placeholder="Daftar Instansi"
                  label="name"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_belanja_id"
                  v-model="master_jenis_belanja_id"
                  required
                  name="master_jenis_belanja_id"
                  :options="referenceData.ref_jenis_belanja"
                  placeholder="Jenis Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col> -->
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_pengadaan_id"
                  v-model="master_jenis_pengadaan_id"
                  required
                  name="master_jenis_pengadaan_id"
                  :options="referenceData.ref_jenis_pengadaan"
                  placeholder="Kriteria Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_sumber_dana_id"
                  v-model="master_sumber_dana_id"
                  name="master_sumber_dana_id"
                  :options="referenceData.ref_sumber_dana"
                  placeholder="Sumber Dana"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length,
              mode: 'pages',
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'total_anggaran'">
                {{ Number(props.row.total_anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'nama_kegiatan'">
                <feather-icon
                  v-if="props.row.prioritas_nasional === 1"
                  class="text-warning"
                  icon="StarIcon"
                  size="14"
                />
                {{ props.row.nama_kegiatan }}
              </span>
              <span v-else-if="props.column.field === 'nama'">
                <b-badge
                  variant="light-info"
                  class="text-wrap mb-50"
                >
                  {{ props.row.domain }}
                </b-badge>
                <span class="d-block">
                  {{ props.row.nama }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'nama_app_infra'">
                <b-badge
                  :variant="(props.row.domain === 'APLIKASI') ? 'light-info' : 'light-success'"
                  class="text-wrap mb-50"
                >
                  {{ props.row.domain }}
                </b-badge>
                <span class="d-block">
                  {{ props.row.nama_app_infra }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <span v-else-if="props.column.field === 'wait_time'">
                {{ props.row.last_update | momentWait }}
              </span>
              <!-- Lihat Rekomendasi Direktur -->
              <span v-else-if="props.column.field == 'status'">
                <b-button
                  v-b-tooltip.hover
                  :variant="statusVariant(props.row.status).class"
                  size="sm"
                  class="text-wrap"
                  style="max-width:120px"
                  title="Lihat Rekomendasi Sementara"
                  @click="openRekomendasiDirektur(props.row)"
                >
                  {{ statusVariant(props.row.status).title }}
                </b-button>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field == 'action'">
                <b-button
                  v-if="yearBudget === '2023'"
                  v-b-tooltip.hover
                  :to="{ name: 'rekomendasi-' + ((props.row.jenis_pengadaan_id === 2) ? 'infrastruktur' : 'aplikasi'), params: { id: ((props.row.jenis_pengadaan_id === 3) ? props.row.app_infra_id : props.row.app_infra_id), type: 'view', utama: props.row.data_utama_id } }"
                  variant="info"
                  size="sm"
                  class="mr-25 mb-25"
                  target="_blank"
                  :title="`Detail ${(props.row.jenis_pengadaan_id === 2) ? 'Infrastruktur' : 'Aplikasi'}`"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="12"
                  /></b-button>
                <b-button
                  v-b-tooltip.hover
                  :to="{ name: 'kegiatan-detail', params: { id: props.row.data_utama_id } }"
                  variant="secondary"
                  size="sm"
                  class="mr-25 mb-25"
                  target="_blank"
                  title="Detail Kegiatan"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="12"
                  /></b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
          <p class="mx-1 my-0">
            Total <b>{{ totalRecords }}</b> Entries
          </p>
        </b-card>

      </b-col>
      <!-- post -->

    </b-row>

    <b-modal
      id="modal-rekomendasi"
      ref="modal-rekomendasi"
      hide-footer
      centered
      size="lg"
      :title="modalTitle"
    >
      <summary-rekomendasi
        :rekap-lanjut="listRekomendasi.continue"
        :rekap-tidak="listRekomendasi.postpone"
      />
    </b-modal>

  </section>

</template>

<script>
import store from '@/store'
import moment from 'moment-business-time'
import vSelect from 'vue-select'
import {
  BAlert, BRow, BButton, BModal, BCol, BCard, BFormGroup,
  BFormInput, VBTooltip, VBModal, BBadge, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/outstandingList'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import SummaryRekomendasi from '../rekomendasi/SummaryRekomendasi.vue'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BBadge,
    BButton,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BAlert,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    SummaryRekomendasi,
    vSelect,
  },
  setup() {
    const {
      activityColors, activityColorsNew, clearanceColors, statusColors, verificationColors,
    } = stColors()
    const { oldColumns, newColumns, subColumns } = tableColumns()

    const yearBudget = localStorage.getItem('tahunAnggaran')
    const columns = (yearBudget === '2023') ? oldColumns : newColumns
    const serverParams = {
      tahun_anggaran: yearBudget,
      sort_by: [
        {
          column: 'last_update',
          asc_desc: 'ASC',
        },
      ],
      start: 0,
      length: 30,
    }

    return {
      yearBudget,
      activityColors,
      activityColorsNew,
      clearanceColors,
      statusColors,
      verificationColors,
      columns,
      subColumns,
      serverParams,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  filters: {
    momentWait(date) {
      const now = new Date()
      const today = moment(now).utc()
      const diff = today.workingDiff(date, 'days')
      return `${diff} Hari`
    },
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 70) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      alertText: '',
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      modalTitle: '',
      isLoading: false,
      totalRecords: 0,
      selectPage: 1,
      rows: [],
      searchTerm: '',
      errorStat: false,
      errorMsg: '',
      listRekomendasi: [],
      userRekoLanjut: [],
      userRekoTidak: [],
      master_data_instansi: '',
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
    }
  },
  created() {
    this.setupTanggal()
  },
  mounted() {
    if (localStorage.getItem('master_instansi_id')) {
      this.master_data_instansi = JSON.parse(localStorage.getItem('master_instansi_id'))
    }
    if (localStorage.getItem('master_jenis_belanja_id')) {
      this.master_jenis_belanja_id = JSON.parse(localStorage.getItem('master_jenis_belanja_id'))
    }
    if (localStorage.getItem('master_jenis_pengadaan_id')) {
      this.master_jenis_pengadaan_id = JSON.parse(localStorage.getItem('master_jenis_pengadaan_id'))
    }
    if (localStorage.getItem('master_sumber_dana_id')) {
      this.master_sumber_dana_id = JSON.parse(localStorage.getItem('master_sumber_dana_id'))
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    this.getReference()
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'antrian' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.doFilter()
    store.commit('app/SET_PAGE', 'antrian')
  },
  methods: {
    setupTanggal() {
      let holidays = []
      if (localStorage.getItem('holidays')) {
        holidays = JSON.parse(localStorage.getItem('holidays'))
      } else {
        this.$http.get('/tanggalan/holidays')
          .then(res => {
            const liburAll = res.data.data
            const libur = []

            Object.keys(liburAll).map(data => {
              libur.push(data)
              return data
            })
            holidays = libur
            localStorage.setItem('holidays', JSON.stringify(libur))
          })
      }
      moment.updateLocale('en', {
        holidays,
      })
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    statusVariant(status) {
      if (this.clearanceColors[status]) {
        return this.clearanceColors[status]
      }
      return {
        title: status,
        class: 'outline-secondary',
      }
    },
    globalFilter() {
      const params = []
      if (this.master_data_instansi) {
        params.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      if (this.master_jenis_belanja_id) {
        params.push({
          column: 'jenis_belanja_id',
          value: String(this.master_jenis_belanja_id.jenis_belanja_id),
        })
        localStorage.setItem('master_jenis_belanja_id', JSON.stringify(this.master_jenis_belanja_id))
      } else {
        localStorage.removeItem('master_jenis_belanja_id')
      }
      if (this.master_jenis_pengadaan_id) {
        params.push({
          column: 'jenis_pengadaan_id',
          value: String(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
        })
        localStorage.setItem('master_jenis_pengadaan_id', JSON.stringify(this.master_jenis_pengadaan_id))
      } else {
        localStorage.removeItem('master_jenis_pengadaan_id')
      }
      if (this.master_sumber_dana_id) {
        params.push({
          column: 'sumber_dana_id',
          value: String(this.master_sumber_dana_id.sumber_dana_id),
        })
        localStorage.setItem('master_sumber_dana_id', JSON.stringify(this.master_sumber_dana_id))
      } else {
        localStorage.removeItem('master_sumber_dana_id')
      }
      return params
    },
    doFilter() {
      const params = this.globalFilter()
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({ start: 0 })
      params.map(param => {
        let columName = param.field
        if (param.field === 'instansi_prefix') {
          columName = 'prefix'
        }
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: columName,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams({ start: 0 })
      const filterz = this.globalFilter()
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry

        let columName = key
        if (key === 'instansi_prefix') {
          columName = 'prefix'
        }
        filterz.push({
          column: columName,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/clearance/outstanding?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.totalRecords = 0
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    openRekomendasi(appData) {
      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: appData.data_utama_id,
      }
      if (appData.aplikasi_id) {
        params.type = 'app'
        params.aplikasi_id = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        params.type = 'infra'
        params.infrastruktur_id = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        params.type = 'data'
        params.data_informasi_id = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        params.type = 'non-teknis'
        params.non_teknis_id = appData.non_teknis_id
      } else if (appData.app_infra_id && appData.jenis_pengadaan_id === 3) {
        params.type = 'app'
        params.aplikasi_id = appData.app_infra_id
      } else if (appData.app_infra_id && appData.jenis_pengadaan_id === 2) {
        params.type = 'infra'
        params.infrastruktur_id = appData.app_infra_id
      }
      this.modalTitle = 'Daftar Rekomendasi Tim Teknis'
      this.$http.get('/recommendation/get-recommendations', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
            this.$refs['modal-rekomendasi'].show()
          }
        })
    },
    openRekomendasiDirektur(appData) {
      this.userRekoLanjut = []
      this.userRekoTidak = []

      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: appData.data_utama_id,
      }
      if (appData.aplikasi_id) {
        params.type = 'app'
        params.aplikasi_id = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        params.type = 'infra'
        params.infrastruktur_id = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        params.type = 'data'
        params.data_informasi_id = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        params.type = 'non-teknis'
        params.non_teknis_id = appData.non_teknis_id
      } else if (appData.app_infra_id && appData.jenis_pengadaan_id === 3) {
        params.type = 'app'
        params.aplikasi_id = appData.app_infra_id
      } else if (appData.app_infra_id && appData.jenis_pengadaan_id === 2) {
        params.type = 'infra'
        params.infrastruktur_id = appData.app_infra_id
      }
      this.modalTitle = 'Daftar Rekomendasi Sementara'
      this.$http.get('/recommendation/get-rekomendasi-sementara', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
            // const dataReko = res.data.data
            // this.listRekomendasi = {
            //   continue: [],
            //   postpone: [],
            // }
            // dataReko.map(value => {
            //   const recomm = value
            //   if (value.is_continue === 1) {
            //     this.listRekomendasi.continue.push(recomm)
            //   } else {
            //     this.listRekomendasi.postpone.push(recomm)
            //   }
            //   return true
            // })
            this.$refs['modal-rekomendasi'].show()
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
